<template>
  <div class="shop-list">
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.keyWord"
          placeholder="输入商户或店铺名称搜索"
          @input="kwChange"
          class="mr20"
        />
        <el-select
          v-model="tableParams.merchantId"
          placeholder="请选择商户"
          @change="reload"
          clearable
          class="mr20"
        >
          <el-option
            v-for="item in commercialList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.shopStatus"
          placeholder="请选择店铺状态"
          @change="reload"
          clearable
        >
          <el-option
            v-for="item in ObjToOpt(shopStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增店铺</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="店铺名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.createTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属商户" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="店铺类型" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ shopTypeObj[scope.row.shopType] || "-" }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="店铺状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{ color: shopStatusColor[scope.row.shopStatus] }">{{
            shopStatusObj[scope.row.shopStatus] || "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="特约商户账号"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.mchNo || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="280" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.shopStatus == '0'"
            type="text"
            size="medium"
            @click.stop="onStart(scope.row)"
            v-has="'commercial_shop_start'"
            >启用</el-button
          >
          <el-button
            v-if="scope.row.shopStatus == '1'"
            type="text"
            size="medium"
            @click.stop="onStop(scope.row)"
            v-has="'commercial_shop_stop'"
            >停用</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onEdit(scope.row)"
            v-has="'commercial_shop_edit'"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onDel(scope.row)"
            v-if="scope.row.shopStatus == '0'"
            v-has="'commercial_shop_del'"
            >删除</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="goSetting(scope.row)"
            >个性化设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :visible.sync="editShow"
      :title="editTitle"
      :close-on-click-modal="false"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 店铺名称 -->
        <el-form-item label="店铺名称" label-width="120px" prop="shopName">
          <el-input
            v-model.trim="params.shopName"
            autocomplete="off"
            maxlength="16"
            placeholder="请填写店铺名称"
          ></el-input>
        </el-form-item>
        <!-- 商户列表 -->
        <el-form-item label="所属商户" label-width="120px" prop="merchantId">
          <el-select
            v-model="params.merchantId"
            placeholder="请选择所属商户"
            filterable
          >
            <el-option
              v-for="item in commercialList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 店铺类型 -->
        <el-form-item label="店铺类型" label-width="120px" prop="shopType">
          <el-select v-model="params.shopType" placeholder="请选择店铺类型">
            <el-option
              v-for="item in ObjToOpt(shopTypeObj)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 特约商户账号 -->
        <el-form-item label="特约商户账号" label-width="120px" prop="mchNo">
          <el-input
            v-model.number="params.mchNo"
            autocomplete="off"
            maxlength="20"
            placeholder="请填写特约商户账号"
          ></el-input>
        </el-form-item>
        <!-- 微信商户账号 -->
        <el-form-item label="微信商户账号" label-width="120px">
          <el-input
            v-model.number="params.shopWeixinAccount"
            autocomplete="off"
            maxlength="20"
            placeholder="请填写微信商户账号"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
    <!--个性化设置弹框-->
    <el-dialog
      :visible.sync="settingDialog"
      title="个性化设置"
      :close-on-click-modal="false"
      width="600px"
      custom-class="add-dialog"
    >
      <el-form :model="settingForm" :rules="rules" ref="settingForm">
        <!-- 类型选择 -->
        <el-form-item label="类型选择" label-width="120px">
          <div style="display: flex">
            <el-checkbox v-model="settingForm.customerServiceTrusteeship"
              >客服托管</el-checkbox
            >
            <el-checkbox v-model="settingForm.turnAir"
              >提前开空调（自动）</el-checkbox
            >
            <el-checkbox v-model="settingForm.groupEnable"
              >团建功能</el-checkbox
            >
          </div>
        </el-form-item>
        <template v-if="settingForm.customerServiceTrusteeship">
          <!-- 托管时段 -->
          <el-form-item label="托管时段" label-width="120px">
            <div style="display: flex">
              <el-time-select
                @change="getBusinessStartTime"
                placeholder="起始时间"
                v-model="settingForm.trusteeshipStartTime"
                :editable="false"
                :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30',
                }"
              >
              </el-time-select>
              <el-time-select
                @change="getBusinessEndTime"
                placeholder="结束时间"
                v-model="settingForm.trusteeshipEndTime"
                :editable="false"
                :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30',
                }"
              >
              </el-time-select>
            </div>
          </el-form-item>
          <!-- 托管电话 -->
          <el-form-item
            label="托管电话"
            label-width="120px"
            prop="trusteeshipPhone"
          >
            <el-input
              v-model.trim="settingForm.trusteeshipPhone"
              placeholder="请填入托管电话"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="settingDialog = false">取 消</el-button>
        <el-button type="primary" @click="goSaveSetting('settingForm')"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getShopList,
  getShopEdit,
  getShopDel,
  getShopStop,
  getShopSetting,
  goSaveShopSetting,
} from "@/api/commercial/shop";
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";
import { shopStatusObj, shopStatusColor, shopTypeObj } from "@/db/objs";
export default {
  data() {
    return {
      rules,
      ObjToOpt,
      shopStatusObj,
      shopStatusColor,
      shopTypeObj,
      // 商户列表
      commercialList: [],
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        keyWord: "", //查询关键字
        merchantId: "", //商户ID
        shopStatus: "", //店铺ID
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      params: {
        shopId: "", //店铺ID
        shopName: "", //店铺名称
        merchantId: "", //所属商户
        mchNo: "", //特约商户账号
        shopType: "", //店铺类型
        shopWeixinAccount:''//微信商户号
      },
      settingForm: {
        customerServiceTrusteeship: false,
        turnAir: false,
        groupEnable:false,
        trusteeshipPhone: "",
        trusteeshipStartTime: "",
        trusteeshipEndTime: "",
      },
      settingDialog: false,
    };
  },
  mounted() {
    this.getShopList();
    this.getAutoCommercialList();
  },
  methods: {
    // 获取开始时间
    getBusinessStartTime(e) {
      this.settingForm.trusteeshipStartTime = e;
    },
    // 获取结束时间
    getBusinessEndTime(e) {
      this.settingForm.trusteeshipEndTime = e;
    },
    // 获取个性化设置
    getShopSetting(shopId) {
      let params = {
        shopId: shopId,
      };
      getShopSetting(params).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.settingForm = {
              customerServiceTrusteeship: res.data.customerServiceTrusteeship,
              turnAir: res.data.turnAir,
              groupEnable:res.data.groupEnable,
              trusteeshipPhone: res.data.trusteeshipPhone,
              trusteeshipStartTime: res.data.trusteeshipStartTime,
              trusteeshipEndTime: res.data.trusteeshipEndTime,
            };
          } else {
            this.settingForm = {
              customerServiceTrusteeship: false,
              turnAir: false,
              groupEnable:false,
              trusteeshipPhone: "",
              trusteeshipStartTime: "",
              trusteeshipEndTime: "",
            };
          }
          this.settingDialog = true;
        }
      });
    },
    // 保存个性化设置
    goSaveSetting(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goSaveShopSetting();
        } else {
          return false;
        }
      });
    },
    goSaveShopSetting() {
      let params = {
        shopId: this.params.shopId,
        merchantId: this.params.merchantId,
        customerServiceTrusteeship: this.settingForm.customerServiceTrusteeship,
        turnAir: this.settingForm.turnAir,
        groupEnable: this.settingForm.groupEnable,
        trusteeshipPhone: this.settingForm.trusteeshipPhone,
        trusteeshipStartTime: this.settingForm.trusteeshipStartTime,
        trusteeshipEndTime: this.settingForm.trusteeshipEndTime,
      };
      goSaveShopSetting(params).then(() => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.settingDialog = false;
      });
    },
    // 打开个性化设置
    goSetting(row) {
      this.params.shopId = row.shopId;
      this.params.merchantId = row.merchantId;
      this.getShopSetting(this.params.shopId);
    },
    // 【请求】列表
    getShopList() {
      let data = this.tableParams;
      getShopList(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },

    // 【请求】商户列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        this.commercialList = res.data;
      });
    },

    // 【请求】新增编辑
    getShopEdit() {
      let data = this.params;
      getShopEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          if (data.shopId) {
            this.getShopList();
          } else {
            this.reload();
          }
        }
      });
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getShopList();
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getShopList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增店铺";
      this.params = {
        shopId: "", //店铺ID
        shopName: "", //店铺名称
        merchantId: "", //所属商户
        mchNo: "", //特约商户账号
        shopType: "", //店铺类型
        shopWeixinAccount:""// 微信商户号
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      console.log("row: ", row);
      this.editShow = true;
      this.editTitle = "编辑店铺";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getShopEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        shopId: row.shopId,
      };
      this.$confirm("你确定要删除该用户吗?", "删除用户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getShopDel(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getShopList();
          }
        });
      });
    },

    // 【监听】启用
    onStart(row) {
      let data = {
        shopId: row.shopId,
        shopStatus: 1,
      };
      this.$confirm("你确定要启用吗?", "启用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getShopStop(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.getShopList();
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        });
      });
    },

    // 【监听】停用
    onStop(row) {
      let data = {
        shopId: row.shopId,
        shopStatus: 0,
      };
      this.$confirm("你确定要停用吗?", "停用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getShopStop(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.getShopList();
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-list {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin: 0.1rem 0;
  display: flex;
  justify-content: flex-end;
}
</style>
